import React, { useEffect, useState } from "react";
import {
    TrashIcon,
    PlusIcon,
} from '@heroicons/react/24/outline'
import InlineCheckbox from "./InlineCheckbox";
import Checkbox from "./Checkbox";
import SelectInput from "./SelectInput";
import TextInput from "./TextInput";
import DateInput from "./DateInput";
import AddInput from "./AddInput";
import TextArea from "./TextArea";
import api from "../api";
import CustomCombobox from "./CustomCombobox";
import Toggle from './Toggle';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { TimeField } from "@mui/x-date-pickers";
import SimpleCombobox from './SimpleCombobox';
import RadioButtons from './RadioButtons';
import PdfIcon from '../assets/PDFIcon';
import { Dialog } from "@headlessui/react";
import Modal from "./Modal";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const visionValues = [
    { name: '20/10', id: 1 },
    { name: '20/15', id: 2 },
    { name: '20/20', id: 3 },
    { name: '20/25', id: 4 },
    { name: '20/30', id: 5 },
    { name: '20/40', id: 6 },
    { name: '20/50', id: 7 },
    { name: '20/60', id: 8 },
    { name: '20/70', id: 9 },
    { name: '20/80', id: 10 },
    { name: '20/100', id: 11 },
    { name: '20/150', id: 12 },
    { name: '20/200', id: 13 },
    { name: '20/400', id: 14 },
    { name: 'CD', id: 15 },
    { name: 'MM', id: 16 },
    { name: 'SPL', id: 17 },
    { name: 'BPL', id: 18 },
    { name: 'MPL', id: 19 },
    { name: 'NPL', id: 20 },
]

const visionFields = [
    'visionWithoutCorrectionLeft',
    'visionWithoutCorrectionRight',
    'visionWithCorrectionLeft',
    'visionWithCorrectionRight',
    'visionWithStenopeicLeft',
    'visionWithStenopeicRight',
]

const radio = [
    { id: "leftEye", default: true, label: "Ojo izquierdo", value: 1 },
    { id: "rightEye", default: false, label: "Ojo derecho", value: 2 }
]

const VisionTension = function (props) {

    const [diagnostics, setDiagnostics] = useState([]);
    const [firstRender, setFirstRender] = useState(false);
    const [visionTension, setVisionTension] = useState({
        diagnoses: undefined,
        motiveOfConsultation: '',
        noTensionMeasurement: false,
        noVisionMeasurement: false,
    })
    const [time, setTime] = useState(undefined)
    const [downloadError, setDownloadError] = useState(undefined);

    const goForward = function (props) {
        let error = false
        if (props.consultation.attentionDate === '') {
            props.handleError('attentionDate', 'Este campo es obligatorio')
            error = true
        }

        if (!error) {
            props.setTab('Refracción')
        }
    }

    useEffect(() => {
        if (props.idConsultation && !props.consultation?.visionTension?.motiveOfConsultation) {
        } else if (props.consultation.visionTension && !firstRender) {
            setVisionTension(props.consultation.visionTension)
            setFirstRender(true)
        } else if (props.consultation && !firstRender) {
            setFirstRender(true)
        }
    }, [props.consultation, props.idConsultation, firstRender])

    useEffect(() => {
        api.diagnostics.getMulti().then((response) => {
            setDiagnostics(response);
        })
    }, [])

    useEffect(() => {
        props.handleChange('visionTension', visionTension)
    }, [visionTension])

    useEffect(() => {
        if (!visionTension.tensionLeft)
            delete visionTension.tensionLeft
    }, [visionTension.tensionLeft])

    useEffect(() => {
        if (!visionTension.tensionRight)
            delete visionTension.tensionRight
    }, [visionTension.tensionRight])

    useEffect(() => {
        if (!visionTension.sampleTime)
            delete visionTension.sampleTime
    }, [visionTension.sampleTime])

    useEffect(() => {
        if (props.errors)
            console.log(props.errors)
    }, [props.errors])

    const handleVision = (field, value) => setVisionTension((prev) => ({ ...prev, [field]: value }))

    useEffect(() => {
        if (time)
            handleVision('sampleTime', time)
    }, [time])

    function hasMatchingAttribute(obj, names) {
        return names.some(name => obj.hasOwnProperty(name));
    }

    const createDiagnostic = async (name) => {
        let response = await api.diagnostics.create(name)
        return response
    }

    return (
        <div>
            <div className="px-4 py-5 sm:p-4">
                <div className="grid grid-cols-3 gap-12">
                    <TextArea required={true} value={visionTension?.motiveOfConsultation} error={props.errors?.motiveOfConsultation} handleChange={handleVision} containerClassName='col-span-2' name='motiveOfConsultation' label='Motivo de consulta' />
                    <CustomCombobox create={createDiagnostic} multiple={true} required={true} value={visionTension?.diagnoses} error={props.errors?.diagnoses} handleChange={handleVision} options={diagnostics} name='diagnoses' containerClassName='col-span-1 w-full relative' label='Diagnóstico' />
                    <div className="col-span-2">
                        <div className="flex">
                            <p>Visión </p>
                            <p className="font-medium text-[#F51515] inline-block ml-2"> *</p>
                        </div>
                        <div className="grid grid-cols-3 gap-4 divide-x-2 mt-3">
                            <div>
                                <p>Sin corrección</p>
                                <div className="grid grid-cols-2 gap-3 mt-3">
                                    <SelectInput placeholder={true} value={visionTension?.visionWithoutCorrectionRight} options={visionValues.map((element) => (<option key={element.id} value={element.id} label={element.name}>{element.name}</option>))} name='visionWithoutCorrectionRight' handleChange={handleVision} label='OD' labelClassName='w-full text-center block' />
                                    <SelectInput placeholder={true} value={visionTension?.visionWithoutCorrectionLeft} options={visionValues.map((element) => (<option key={element.id} value={element.id} label={element.name}>{element.name}</option>))} name='visionWithoutCorrectionLeft' handleChange={handleVision} label='OI' labelClassName='w-full text-center block' />
                                </div>
                            </div>
                            <div className="pl-4">
                                <p>Con corrección</p>
                                <div className="grid grid-cols-2 gap-6 mt-3">
                                    <SelectInput placeholder={true} value={visionTension?.visionWithCorrectionRight} options={visionValues.map((element) => (<option key={element.id} value={element.id} label={element.name}>{element.name}</option>))} name='visionWithCorrectionRight' handleChange={handleVision} label='OD' labelClassName='w-full text-center block' />
                                    <SelectInput placeholder={true} value={visionTension?.visionWithCorrectionLeft} containerClassName='flex w-full justify-center' options={visionValues.map((element) => (<option key={element.id} value={element.id} label={element.name}>{element.name}</option>))} name='visionWithCorrectionLeft' handleChange={handleVision} label='OI' labelClassName='w-full text-center block' />
                                </div>
                            </div>
                            <div className="pl-4">
                                <p>Con estenopeico</p>
                                <div className="grid grid-cols-2 gap-6 mt-3">
                                    <SelectInput placeholder={true} value={visionTension?.visionWithoutStenopeicRight} options={visionValues.map((element) => (<option key={element.id} value={element.id} label={element.name}>{element.name}</option>))} name='visionWithStenopeicRight' handleChange={handleVision} label='OD' labelClassName='w-full text-center block' />
                                    <SelectInput placeholder={true} value={visionTension?.visionWithoutStenopeicLeft} options={visionValues.map((element) => (<option key={element.id} value={element.id} label={element.name}>{element.name}</option>))} name='visionWithStenopeicLeft' handleChange={handleVision} label='OI' labelClassName='w-full text-center block' />
                                </div>
                            </div>
                        </div>
                        <InlineCheckbox value={visionTension?.noVisionMeasurement} name='noVisionMeasurement' handleChange={handleVision} containerClassName='col-span-4 flex mt-4' label='No fue necesaria una medición' />
                        {props.visionFieldsErrors && <p className="mt-2 text-sm text-red-600" id={`${props.name}-error`}>
                            {props.visionFieldsErrors}
                        </p>}
                    </div>
                    <div>
                        <div className="flex">
                            <p>Tensión</p>
                            <p className="font-medium text-[#F51515] inline-block ml-2"> *</p>
                        </div>
                        <div className="grid grid-cols-4 gap-x-3 gap-y-6 mt-3 h-fit">
                            <TextInput onKeyDown={(event) => { if (/[0-9]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab') { } else { event.preventDefault(); } }} value={visionTension?.tensionRight} description='0 a 100' name='tensionRight' handleChange={handleVision} label='OD' labelClassName='w-full text-center block' />
                            <TextInput onKeyDown={(event) => { if (/[0-9]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab') { } else { event.preventDefault(); } }} value={visionTension?.tensionLeft} description='0 a 100' name='tensionLeft' handleChange={handleVision} label='OI' labelClassName='w-full text-center block' />
                            <div>
                                <label className="text-sm font-medium text-gray-700 whitespace-nowrap">Hora de muestra</label>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <TimeField value={visionTension.sampleTime ? new Date().setHours(visionTension?.sampleTime.split(':')[0], visionTension?.sampleTime.split(':')[1]) : null} format='HH:mm' onChange={(value) => setTime(`${value.getHours().toString().padStart(2, '0')}:${value.getMinutes().toString().padStart(2, '0')}`)} size='small' />
                                </LocalizationProvider>
                            </div>
                            <InlineCheckbox value={visionTension?.noTensionMeasurement} name='noTensionMeasurement' handleChange={handleVision} containerClassName='col-span-4 flex' label='No fue necesaria una medición' />
                            <div className="col-span-4 font-medium text-[#F51515] text-xs -mt-4">
                                <p>{props.errorTensionRight}</p>
                                <p>{!props.errorTensionRight && props.errorTensionLeft}</p>
                                <p>{(!props.errorTensionRight && !props.errorTensionLeft) && props.errorSampleTime}</p>
                                <p>{props.errors?.tensionLeft}</p>
                                <p>{props.errors?.tensionRight}</p>
                                <p>{props.errors?.sampleTime}</p>
                                <p>{props.errors?.nonFieldErrors}</p>
                            </div>
                        </div>
                    </div>
                    <TextArea error={props.errors?.annotations} required={true} value={visionTension?.annotations} handleChange={handleVision} name='annotations' label='Hallazgos clínicos' containerClassName='col-span-3' />
                </div>
            </div>
            <div className="px-4 py-4 sm:px-6 bg-gray-100">
                <div className="flex justify-end">
                    <button
                        onClick={() => goForward(props)}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                    >
                        Siguiente
                    </button>
                </div>
            </div>
        </div >
    )
}

const Refraction = function (props) {

    const [generatePrescription, setGeneratePrescription] = useState(false);
    const [refraction, setRefraction] = useState({})
    const [prescription, setPrescription] = useState({})
    const [refractionRecordsOpen, setRefractionRecordsOpen] = useState(false)
    const [aux, setAux] = useState(undefined)
    const [firstRender, setFirstRender] = useState(false)
    const [loadingDownload, setLoadingDownload] = useState(false)
    const [downloadError, setDownloadError] = useState(undefined)
    const [selectedDate, setSelectedDate] = useState(undefined)

    useEffect(() => {
        if (props.consultation.refraction && !firstRender) {
            setRefraction(props.consultation.refraction)
            setFirstRender(true)
        } else if (props.consultation && !firstRender) {
            setFirstRender(true)
        }
    }, [props.consultation, firstRender])

    const handleRefraction = (field, value) => setRefraction((prev) => ({ ...prev, [field]: value }))
    const handlePrescription = (field, value) => setPrescription((prev) => ({ ...prev, [field]: value }))
    const handleAux = (field, value) => setAux(value)

    useEffect(() => {
        if (generatePrescription) {
            handlePrescription('farVisionSphericalRight', refraction.farVisionSphericalRight)
            handlePrescription('farVisionCylindricalRight', refraction.farVisionCylindricalRight)
            handlePrescription('farVisionAxisRight', refraction.farVisionAxisRight)
            handlePrescription('farVisionSphericalLeft', refraction.farVisionSphericalLeft)
            handlePrescription('farVisionCylindricalLeft', refraction.farVisionCylindricalLeft)
            handlePrescription('farVisionAxisLeft', refraction.farVisionAxisLeft)
            handlePrescription('nearVisionSphericalRight', refraction.nearVisionSphericalRight)
            handlePrescription('nearVisionCylindricalRight', refraction.nearVisionCylindricalRight)
            handlePrescription('nearVisionAxisRight', refraction.nearVisionAxisRight)
            handlePrescription('nearVisionSphericalLeft', refraction.nearVisionSphericalLeft)
            handlePrescription('nearVisionCylindricalLeft', refraction.nearVisionCylindricalLeft)
            handlePrescription('nearVisionAxisLeft', refraction.nearVisionAxisLeft)
            handleRefraction('eyeglassPrescription', prescription)
        } else {
            delete refraction?.eyeglassPrescription
        }
    }, [generatePrescription])

    useEffect(() => {
        if (generatePrescription)
            handleRefraction('eyeglassPrescription', prescription)
    }, [prescription])

    useEffect(() => {
        props.handleChange('refraction', refraction)
    }, [refraction])


    const downloadPDF = function () {
        setLoadingDownload(true);
        setDownloadError('');
        api.refraction.get({
            patient: props.consultation.patient, doctor: props.consultation.doctor, generalObservations: props.consultation.refraction.generalObservations,
            eyeglassPrescription: props.consultation.refraction.eyeglassPrescription
        }).then(res => {
            setLoadingDownload(false)
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Refraccion.pdf');
            document.body.appendChild(link);
            link.click();
        }, async (err) => {
            setDownloadError(JSON.parse(await err.response.data.text()).detail)
            setLoadingDownload(false)
        })
    }

    useEffect(() => {
        if (!refractionRecordsOpen) {
            setSelectedDate(undefined)
            setAux(undefined)
        }
    }, [refractionRecordsOpen])

    const refractionRecords = (
        <>
            {props.refractionRecords && <div className='grid grid-cols-3 gap-6'>
                <div className={classNames(selectedDate ? 'col-span-1' : 'col-span-3', "mt-3 text-center sm:mt-5")}>
                    <Dialog.Title as="h3" className="leading-6 text-[#75C9EE] font-semibold text-2xl">
                        Consultas anteriores
                    </Dialog.Title>
                    <div className="mt-8">
                        <SelectInput containerStyle='mx-auto' placeholder={true} placeholderText='Seleccionar fecha' value={aux} options={props.refractionRecords.map((element, idx) => (<option key={idx} value={idx + 1} label={element.consultationDate}>{element.consultationDate}</option>))} name='aux' handleChange={handleAux} label='Selecciona una fecha' />
                        <button type="button" onClick={() => setSelectedDate(aux)} className="w-full mt-10 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary sm:col-start-2 sm:text-sm">
                            Buscar
                        </button>
                        <button type="button" onClick={() => setRefractionRecordsOpen(false)} className="w-full mt-4 inline-flex justify-center rounded-md border shadow-sm px-4 py-2 bg-white text-base font-medium text-black sm:col-start-2 sm:text-sm">
                            Cancelar
                        </button>
                    </div>
                </div>
                {selectedDate &&
                    <div className="col-span-2 flex flex-col gap-3">
                        <div>
                            <h1 className="text-lg leading-6 font-medium text-gray-900">Visión lejana</h1>
                            <div className='grid grid-cols-5 gap-4 mt-4'>
                                <p className='text-end mt-7'>OD</p>
                                <TextInput value={props.refractionRecords[selectedDate - 1]?.refraction.farVisionSphericalRight ? props.refractionRecords[selectedDate - 1]?.refraction.farVisionSphericalRight : '--'} disabled={true} name='farVisionSphericalRight' label='Esf' labelClassName='w-full text-center block' />
                                <TextInput value={props.refractionRecords[selectedDate - 1]?.refraction.farVisionCylindricalRight ? props.refractionRecords[selectedDate - 1]?.refraction.farVisionCylindricalRight : '--'} disabled={true} name='farVisionCylindricalRight' label='Cil.' labelClassName='w-full text-center block' />
                                <TextInput value={props.refractionRecords[selectedDate - 1]?.refraction.farVisionAxisRight ? props.refractionRecords[selectedDate - 1]?.refraction.farVisionAxisRight : '--'} disabled={true} name='farVisionAxisRight' label='Eje' labelClassName='w-full text-center block' />
                                <TextInput value={props.refractionRecords[selectedDate - 1]?.refraction.farVisionVisualAcuityRight ? props.refractionRecords[selectedDate - 1]?.refraction.farVisionVisualAcuityRight : '--'} disabled={true} name='farVisionVisualAcuityRight' label='Av.' labelClassName='w-full text-center block' />
                                <p className='text-end mt-2'>OI</p>
                                <TextInput value={props.refractionRecords[selectedDate - 1]?.refraction.farVisionSphericalLeft ? props.refractionRecords[selectedDate - 1]?.refraction.farVisionSphericalLeft : '--'} disabled={true} name='farVisionSphericalLeft' />
                                <TextInput value={props.refractionRecords[selectedDate - 1]?.refraction.farVisionCylindricalLeft ? props.refractionRecords[selectedDate - 1]?.refraction.farVisionCylindricalLeft : '--'} disabled={true} name='farVisionCylindricalLeft' />
                                <TextInput value={props.refractionRecords[selectedDate - 1]?.refraction.farVisionAxisLeft ? props.refractionRecords[selectedDate - 1]?.refraction.farVisionAxisLeft : '--'} disabled={true} name='farVisionAxisLeft' />
                                <TextInput value={props.refractionRecords[selectedDate - 1]?.refraction.farVisionVisualAcuityLeft ? props.refractionRecords[selectedDate - 1]?.refraction.farVisionVisualAcuityLeft : '--'} disabled={true} name='farVisionVisualAcuityLeft' />
                            </div>
                        </div>
                        <div>
                            <h1 className="text-lg leading-6 font-medium text-gray-900">Visión cercana</h1>
                            <div className='grid grid-cols-5 gap-4 mt-4'>
                                <p className='text-end mt-7'>OD</p>
                                <TextInput value={props.refractionRecords[selectedDate - 1]?.refraction.nearVisionSphericalRight ? props.refractionRecords[selectedDate - 1]?.refraction.nearVisionSphericalRight : '--'} handleChange={handleRefraction} name='nearVisionSphericalRight' label='Esf' labelClassName='w-full text-center block' />
                                <TextInput value={props.refractionRecords[selectedDate - 1]?.refraction.nearVisionCylindricalRight ? props.refractionRecords[selectedDate - 1]?.refraction.nearVisionCylindricalRight : '--'} handleChange={handleRefraction} name='nearVisionCylindricalRight' label='Cil.' labelClassName='w-full text-center block' />
                                <TextInput value={props.refractionRecords[selectedDate - 1]?.refraction.nearVisionAxisRight ? props.refractionRecords[selectedDate - 1]?.refraction.nearVisionAxisRight : '--'} handleChange={handleRefraction} name='nearVisionAxisRight' label='Eje' labelClassName='w-full text-center block' />
                                <TextInput value={props.refractionRecords[selectedDate - 1]?.refraction.nearVisionVisualAcuityRight ? props.refractionRecords[selectedDate - 1]?.refraction.nearVisionVisualAcuityRight : '--'} handleChange={handleRefraction} name='nearVisionVisualAcuityRight' label='Av.' labelClassName='w-full text-center block' />
                                <p className='text-end mt-2'>OI</p>
                                <TextInput value={props.refractionRecords[selectedDate - 1]?.refraction.nearVisionSphericalLeft ? props.refractionRecords[selectedDate - 1]?.refraction.nearVisionSphericalLeft : '--'} handleChange={handleRefraction} name='nearVisionSphericalLeft' />
                                <TextInput value={props.refractionRecords[selectedDate - 1]?.refraction.nearVisionCylindricalLeft ? props.refractionRecords[selectedDate - 1]?.refraction.nearVisionCylindricalLeft : '--'} handleChange={handleRefraction} name='nearVisionCylindricalLeft' />
                                <TextInput value={props.refractionRecords[selectedDate - 1]?.refraction.nearVisionAxisLeft ? props.refractionRecords[selectedDate - 1]?.refraction.nearVisionAxisLeft : '--'} handleChange={handleRefraction} name='nearVisionAxisLeft' />
                                <TextInput value={props.refractionRecords[selectedDate - 1]?.refraction.nearVisionVisualAcuityLeft ? props.refractionRecords[selectedDate - 1]?.refraction.nearVisionVisualAcuityLeft : '--'} handleChange={handleRefraction} name='nearVisionVisualAcuityLeft' />
                            </div>
                        </div>
                    </div>}
            </div>}
        </>
    )

    return (
        <div>
            <Modal content={refractionRecords} open={refractionRecordsOpen} setOpen={setRefractionRecordsOpen} />
            <div className="px-4 py-6 sm:p-4">
                <div className="grid grid-cols-2">
                    <div className="flex flex-col gap-6">
                        <div>
                            <h1 className="text-lg leading-6 font-medium text-gray-900">Visión lejana</h1>
                            <div className='grid grid-cols-5 gap-4 w-2/3 mt-4'>
                                <p className='text-end mt-7'>OD</p>
                                <TextInput errorMessage={props.errors?.farVisionSphericalRight} onKeyDown={(event) => { if (/[0-9\.\+-]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab') { } else { event.preventDefault(); } }} value={refraction?.farVisionSphericalRight} handleChange={handleRefraction} name='farVisionSphericalRight' label='Esf' labelClassName='w-full text-center block' />
                                <TextInput errorMessage={props.errors?.farVisionCylindricalRight} onKeyDown={(event) => { if (/[0-9\.\+-]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab') { } else { event.preventDefault(); } }} value={refraction?.farVisionCylindricalRight} handleChange={handleRefraction} name='farVisionCylindricalRight' label='Cil.' labelClassName='w-full text-center block' />
                                <TextInput errorMessage={props.errors?.farVisionAxisRight} onKeyDown={(event) => { if (/[0-9\.\+-]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab') { } else { event.preventDefault(); } }} value={refraction?.farVisionAxisRight} handleChange={handleRefraction} name='farVisionAxisRight' label='Eje' labelClassName='w-full text-center block' />
                                <SelectInput errorMessage={props.errors?.farVisionVisualAcuityRight} containerStyle='-mt-1' className='shadow-sm w-fit mt-1 mx-auto pr-6 border border-gray-300 block sm:text-sm rounded-md' placeholder={true} value={refraction?.farVisionVisualAcuityRight} options={visionValues.map((element) => (<option key={element.id} value={element.id} label={element.name}>{element.name}</option>))} name='farVisionVisualAcuityRight' handleChange={handleRefraction} label='Av.' labelClassName='w-full text-center block' />
                                <p className='text-end mt-2'>OI</p>
                                <TextInput errorMessage={props.errors?.farVisionSphericalLeft} onKeyDown={(event) => { if (/[0-9\.\+-]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab') { } else { event.preventDefault(); } }} value={refraction?.farVisionSphericalLeft} handleChange={handleRefraction} name='farVisionSphericalLeft' />
                                <TextInput errorMessage={props.errors?.farVisionCylindricalLeft} onKeyDown={(event) => { if (/[0-9\.\+-]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab') { } else { event.preventDefault(); } }} value={refraction?.farVisionCylindricalLeft} handleChange={handleRefraction} name='farVisionCylindricalLeft' />
                                <TextInput errorMessage={props.errors?.farVisionAxisLeft} onKeyDown={(event) => { if (/[0-9\.\+-]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab') { } else { event.preventDefault(); } }} value={refraction?.farVisionAxisLeft} handleChange={handleRefraction} name='farVisionAxisLeft' />
                                <SelectInput errorMessage={props.errors?.farVisionVisualAcuityLeft} className='shadow-sm w-fit mt-1 mx-auto pr-6 border border-gray-300 block sm:text-sm rounded-md' placeholder={true} value={refraction?.farVisionVisualAcuityLeft} options={visionValues.map((element) => (<option key={element.id} value={element.id} label={element.name}>{element.name}</option>))} name='farVisionVisualAcuityLeft' handleChange={handleRefraction} labelClassName='w-full text-center block' />
                            </div>
                        </div>
                        <div>
                            <h1 className="text-lg leading-6 font-medium text-gray-900">Visión cercana</h1>
                            <div className='grid grid-cols-5 gap-4 w-2/3 mt-4'>
                                <p className='text-end mt-7'>OD</p>
                                <TextInput errorMessage={props.errors?.nearVisionSphericalRight} onKeyDown={(event) => { if (/[0-9\.\+-]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab') { } else { event.preventDefault(); } }} value={refraction?.nearVisionSphericalRight} handleChange={handleRefraction} name='nearVisionSphericalRight' label='Esf' labelClassName='w-full text-center block' />
                                <TextInput errorMessage={props.errors?.nearVisionCylindricalRight} onKeyDown={(event) => { if (/[0-9\.\+-]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab') { } else { event.preventDefault(); } }} value={refraction?.nearVisionCylindricalRight} handleChange={handleRefraction} name='nearVisionCylindricalRight' label='Cil.' labelClassName='w-full text-center block' />
                                <TextInput errorMessage={props.errors?.nearVisionAxisRight} onKeyDown={(event) => { if (/[0-9\.\+-]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab') { } else { event.preventDefault(); } }} value={refraction?.nearVisionAxisRight} handleChange={handleRefraction} name='nearVisionAxisRight' label='Eje' labelClassName='w-full text-center block' />
                                <SelectInput errorMessage={props.errors?.nearVisionVisualAcuityRight} containerStyle='-mt-1' className='shadow-sm w-fit mt-1 mx-auto pr-6 border border-gray-300 block sm:text-sm rounded-md' placeholder={true} value={refraction?.nearVisionVisualAcuityRight} options={visionValues.map((element) => (<option key={element.id} value={element.id} label={element.name}>{element.name}</option>))} name='nearVisionVisualAcuityRight' handleChange={handleRefraction} label='Av.' labelClassName='w-full text-center block' />
                                <p className='text-end mt-2'>OI</p>
                                <TextInput errorMessage={props.errors?.nearVisionSphericalLeft} onKeyDown={(event) => { if (/[0-9\.\+-]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab') { } else { event.preventDefault(); } }} value={refraction?.nearVisionSphericalLeft} handleChange={handleRefraction} name='nearVisionSphericalLeft' />
                                <TextInput errorMessage={props.errors?.nearVisionCylindricalLeft} onKeyDown={(event) => { if (/[0-9\.\+-]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab') { } else { event.preventDefault(); } }} value={refraction?.nearVisionCylindricalLeft} handleChange={handleRefraction} name='nearVisionCylindricalLeft' />
                                <TextInput errorMessage={props.errors?.nearVisionAxisLeft} onKeyDown={(event) => { if (/[0-9\.\+-]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab') { } else { event.preventDefault(); } }} value={refraction?.nearVisionAxisLeft} handleChange={handleRefraction} name='nearVisionAxisLeft' />
                                <SelectInput errorMessage={props.errors?.nearVisionVisualAcuityLeft} className='shadow-sm w-fit mt-1 mx-auto pr-6 border border-gray-300 block sm:text-sm rounded-md' placeholder={true} value={refraction?.nearVisionVisualAcuityLeft} options={visionValues.map((element) => (<option key={element.id} value={element.id} label={element.name}>{element.name}</option>))} name='nearVisionVisualAcuityLeft' handleChange={handleRefraction} labelClassName='w-full text-center block' />
                            </div>
                        </div>
                        <TextInput errorMessage={props.errors?.intrapupillaryDistance} containerClassName='w-fit' className='shadow-sm pl-2 w-20 border border-gray-300 block sm:text-sm rounded-md' onKeyDown={(event) => { if (/[0-9\.\+-]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab') { } else { event.preventDefault(); } }} value={refraction?.intrapupillaryDistance} handleChange={handleRefraction} name='intrapupillaryDistance' label='Distancia interpupilar' labelClassName='text-center block' />
                        <TextArea error={props.errors?.generalObservations} value={refraction.generalObservations} handleChange={handleRefraction} name='generalObservations' label='Observaciones generales' />
                    </div>
                    <div className="flex flex-col">
                        <div className='flex gap-3 p-4'>
                            <Toggle value={refraction.eyeglassPrescription ? true : false} handleChange={setGeneratePrescription} />
                            <p className='text-primary font-bold text-lg -mt-1'>Generar receta de anteojos</p>
                        </div>
                        {generatePrescription &&
                            <div className="flex flex-col gap-6 ml-6">
                                <div>
                                    <h1 className="text-lg leading-6 font-medium text-gray-900">Visión lejana</h1>
                                    <div className='grid grid-cols-4 gap-4 w-2/3 mt-4 -ml-10'>
                                        <p className='text-end mt-7'>OD</p>
                                        <TextInput value={refraction?.eyeglassPrescription?.farVisionSphericalRight} handleChange={handlePrescription} name='farVisionSphericalRight' label='Esf' labelClassName='w-full text-center block' />
                                        <TextInput value={refraction?.eyeglassPrescription?.farVisionCylindricalRight} handleChange={handlePrescription} name='farVisionCylindricalRight' label='Cil.' labelClassName='w-full text-center block' />
                                        <TextInput value={refraction?.eyeglassPrescription?.farVisionAxisRight} handleChange={handlePrescription} name='farVisionAxisRight' label='Eje' labelClassName='w-full text-center block' />
                                        <p className='text-end mt-2'>OI</p>
                                        <TextInput value={refraction?.eyeglassPrescription?.farVisionSphericalLeft} handleChange={handlePrescription} name='farVisionSphericalLeft' />
                                        <TextInput value={refraction?.eyeglassPrescription?.farVisionCylindricalLeft} handleChange={handlePrescription} name='farVisionCylindricalLeft' />
                                        <TextInput value={refraction?.eyeglassPrescription?.farVisionAxisLeft} handleChange={handlePrescription} name='farVisionAxisLeft' />
                                    </div>
                                </div>
                                <div>
                                    <h1 className="text-lg leading-6 font-medium text-gray-900">Visión cercana</h1>
                                    <div className='grid grid-cols-4 gap-4 w-2/3 mt-4 -ml-10'>
                                        <p className='text-end mt-7'>OD</p>
                                        <TextInput value={refraction?.eyeglassPrescription?.nearVisionSphericalRight} handleChange={handlePrescription} name='nearVisionSphericalRight' label='Esf' labelClassName='w-full text-center block' />
                                        <TextInput value={refraction?.eyeglassPrescription?.nearVisionCylindricalRight} handleChange={handlePrescription} name='nearVisionCylindricalRight' label='Cil.' labelClassName='w-full text-center block' />
                                        <TextInput value={refraction?.eyeglassPrescription?.nearVisionAxisRight} handleChange={handlePrescription} name='nearVisionAxisRight' label='Eje' labelClassName='w-full text-center block' />
                                        <p className='text-end mt-2'>OI</p>
                                        <TextInput value={refraction?.eyeglassPrescription?.nearVisionSphericalLeft} handleChange={handlePrescription} name='nearVisionSphericalLeft' />
                                        <TextInput value={refraction?.eyeglassPrescription?.nearVisionCylindricalLeft} handleChange={handlePrescription} name='nearVisionCylindricalLeft' />
                                        <TextInput value={refraction?.eyeglassPrescription?.nearVisionAxisLeft} handleChange={handlePrescription} name='nearVisionAxisLeft' />
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 gap-4 w-2/3 ml-4">
                                    <InlineCheckbox value={refraction?.eyeglassPrescription?.photochromic} handleChange={handlePrescription} name='photochromic' label='Fotocromático' />
                                    <InlineCheckbox value={refraction?.eyeglassPrescription?.monofocal} handleChange={handlePrescription} name='monofocal' label='Monofocal' />
                                    <InlineCheckbox value={refraction?.eyeglassPrescription?.antiGlare} handleChange={handlePrescription} name='antiGlare' label='Antireflejo' />
                                    <InlineCheckbox value={refraction?.eyeglassPrescription?.bifocal} handleChange={handlePrescription} name='bifocal' label='Bifocal' />
                                    <InlineCheckbox value={refraction?.eyeglassPrescription?.uvx} handleChange={handlePrescription} name='uvx' label='UVX' />
                                    <InlineCheckbox value={refraction?.eyeglassPrescription?.multifocal} handleChange={handlePrescription} name='multifocal' label='Multifocal' />
                                    <InlineCheckbox value={refraction?.eyeglassPrescription?.focalFilter} handleChange={handlePrescription} name='focalFilter' label='Filtro azul' />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="px-4 py-4 sm:px-6 bg-gray-100">
                <div className="flex justify-between">
                    <button
                        onClick={() => props.setTab('Visión y Tensión')}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm bg-white text-primary hover:text-primary"
                    >
                        Anterior
                    </button>
                    <div className="flex justify-end">
                        {refractionRecords && <button type='button' onClick={() => setRefractionRecordsOpen(true)} className="text-black font-bold leading-[13px] underline mr-10 text-[10px] h-fit my-auto">
                            Ver consultas anteriores
                        </button>}
                        {downloadError &&
                            <p className="font-medium text-[#F51515] text-xs w-1/2 mt-1">
                                {downloadError}
                            </p>
                        }
                        {loadingDownload ? <div className="w-5 h-5 mx-5 border-b-2 border-primary rounded-full animate-spin"></div> : <button
                            onClick={() => downloadPDF()}
                            type="button"
                            className="flex gap-1 mt-2 font-bold text-xs"
                        >
                            <PdfIcon />
                            <p className="mt-1">Imprimir receta</p>
                        </button>}
                        <button
                            onClick={() => props.setTab('Medicación')}
                            type="button"
                            className="inline-flex items-center px-4 py-2 ml-10 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        >
                            Siguiente
                        </button>
                    </div>
                </div>
            </div>
        </div >
    )
}

const Medications = function (props) {

    const presentation = [
        { id: 1, name: 'Gotas' },
        { id: 2, name: 'Comprimidos' },
        { id: 3, name: 'Ungüento' },
        { id: 4, name: 'Gel' },
        { id: 5, name: 'Loción' }
    ]

    const frequency = [
        { id: 1, name: 'Minutos' },
        { id: 2, name: 'Horas' },
        { id: 3, name: 'Días' },
        { id: 4, name: 'Semanas' },
        { id: 5, name: 'Meses' },
    ]

    const period = [
        { id: 1, name: 'Días' },
        { id: 2, name: 'Semanas' },
        { id: 3, name: 'Meses' },
        { id: 4, name: 'Años' },
        { id: 5, name: 'De por vida' },
    ]

    const [firstRender, setFirstRender] = useState(false)
    const [firstRenderB, setFirstRenderB] = useState(false)
    const [medications, setMedications] = useState([{ medicine: '', quantity: '', presentation: 1, frequencyNumber: '', frequency: 2, periodNumber: '', period: 1, observations: '', delivered: false }])
    const [loadingDownload, setLoadingDownload] = useState(false)
    const [downloadError, setDownloadError] = useState(undefined)
    const [medicationsRecordsOpen, setMedicationsRecordsOpen] = useState(false)
    const [aux, setAux] = useState(undefined)
    const [selectedDate, setSelectedDate] = useState(undefined)
    const [nonMedicationTreatment, setNonMedicationTreatment] = useState(undefined)

    const handleMedication = (field, value, index) => {
        let obj = medications.slice()
        if (!obj[index]) {
            obj[index] = { medicine: '', quantity: '', presentation: 1, frequencyNumber: '', frequency: 2, periodNumber: '', period: 1, observations: '', delivered: false }
        }
        if (field === 'period' && value === 5) {
            delete obj[index].periodNumber
        }
        obj[index][field] = value
        setMedications(obj);
    }
    const addMedication = () => setMedications((prev) => ([...prev, { medicine: '', quantity: '', presentation: 1, frequencyNumber: '', frequency: 2, periodNumber: '', period: 1, observations: '', delivered: false }]))
    const removeMedication = (indx) => {
        let auxMeds = medications.slice(0)
        auxMeds.splice(indx, 1)
        setMedications(auxMeds)
    }
    const handleAux = (field, value) => setAux(value)

    const createMedication = async (name) => {
        let response = await api.medicines.create(name)
        return response
    }

    const handleNonMedicationTreatment = (field, value) => setNonMedicationTreatment(value);

    useEffect(() => {
        if (medications[0]?.medicine !== '')
            props.handleChange('medications', medications.map((element) => ({ ...element, medicine: element.medicine })))
    }, [medications])

    useEffect(() => {
        if (nonMedicationTreatment || nonMedicationTreatment === '')
            props.handleChange('nonMedicationTreatment', nonMedicationTreatment)
    }, [nonMedicationTreatment])

    useEffect(() => {
        if (props.consultation.medications && !firstRender) {
            setMedications(props.consultation.medications)
            setFirstRender(true)
        } else if (props.consultation && !firstRender) {
            setFirstRender(true)
        }
    }, [props.consultation, firstRender])

    useEffect(() => {
        if (props.consultation.nonMedicationTreatment && !firstRender) {
            setNonMedicationTreatment(props.consultation.nonMedicationTreatment)
            setFirstRender(true)
        } else if (props.consultation && !firstRender) {
            setFirstRender(true)
        }
    }, [props.consultation, firstRender])

    const checkIfEmpty = (medication) => {
        if (medication.medicine || medication.periodNumber !== "" || medication.quantity !== "" || medication.frequencyNumber !== "" || medication.observations !== "")
            return false
        else
            return true
    }

    const downloadPDF = function () {
        setLoadingDownload(true);
        setDownloadError('');
        let request = {};
        if (props.consultation.medications && !props.consultation.medications.map((element) => checkIfEmpty(element) ? null : element).every((element) => element === null)) {
            request = { ...props.consultation, medications: props.consultation.medications.map((element) => element.period === 5 ? ({ ...element, periodNumber: 0 }) : element) }
            console.log(request)
        } else {
            request = { ...props.consultation }
            delete request.medications
        }
        api.medication.get(request).then(res => {
            setLoadingDownload(false)
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Medicamentos.pdf');
            document.body.appendChild(link);
            link.click();
        }, async (err) => {
            setDownloadError(JSON.parse(await err.response.data.text()).detail)
            setLoadingDownload(false)
        })
    }

    useEffect(() => {
        if (!medicationsRecordsOpen) {
            setSelectedDate(undefined)
            setAux(undefined)
        }
    }, [medicationsRecordsOpen])

    const medicationsRecords = (
        <>
            {props.medicationsRecords && <div className='grid grid-cols-3 gap-6'>
                <div className={classNames(selectedDate ? 'col-span-1' : 'col-span-3', "mt-3 text-center sm:mt-5")}>
                    <Dialog.Title as="h3" className="leading-6 text-[#75C9EE] font-semibold text-2xl">
                        Consultas anteriores
                    </Dialog.Title>
                    <div className="mt-8">
                        <SelectInput containerStyle='mx-auto' placeholder={true} placeholderText='Seleccionar fecha' value={aux} options={props.medicationsRecords.map((element, idx) => (<option key={idx} value={idx + 1} label={element.consultationDate}>{element.consultationDate}</option>))} name='aux' handleChange={handleAux} label='Selecciona una fecha' />
                        <button type="button" onClick={() => setSelectedDate(aux)} className="w-full mt-10 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary sm:col-start-2 sm:text-sm">
                            Buscar
                        </button>
                        <button type="button" onClick={() => setMedicationsRecordsOpen(false)} className="w-full mt-4 inline-flex justify-center rounded-md border shadow-sm px-4 py-2 bg-white text-base font-medium text-black sm:col-start-2 sm:text-sm">
                            Cancelar
                        </button>
                    </div>
                </div>
                {selectedDate &&
                    <div className="col-span-2 flex flex-col gap-3">
                        <p className='text-primary font-semibold text-sm leading-[20px]'>{props.medicationsRecords[selectedDate - 1].consultationDate}</p>
                        <div className='grid grid-cols-8 gap-6 text-sm font-semibold leading-[16px] text-center'>
                            <p className="col-span-2">Medicamento</p>
                            <p className="col-span-1">Cant.</p>
                            <p className="col-span-2">Presentación</p>
                            <p className="col-span-1">Freq.</p>
                            <p className="col-span-2">Periodo</p>
                        </div>
                        {props.medicationsRecords[selectedDate - 1].medications.map((element) => (
                            <div key={element.id} className='grid grid-cols-8 gap-6 border border-[#75C9EE] bg-[#FAFAFA] rounded-[7px] py-2 text-center'>
                                <p className="col-span-2">{element.medicine.name}</p>
                                <p className="col-span-1">{element.quantity}</p>
                                <p className="col-span-2">{presentation[element.presentation - 1]?.name}</p>
                                <p className="col-span-1">{`${element.frequencyNumber} ${frequency[element.frequency - 1].name}`}</p>
                                <p className="col-span-2">{`${element.periodNumber} ${period[element.period - 1].name}`}</p>
                            </div>
                        ))}
                    </div>}
            </div>}
        </>
    )

    return (
        <div>
            <Modal size={selectedDate ? 'sm:max-w-5xl w-full' : 'sm:max-w-5xl '} content={medicationsRecords} open={medicationsRecordsOpen} setOpen={setMedicationsRecordsOpen} />
            <div className="px-4 py-5 sm:p-4">
                <div className="flex-col">
                    <h1 className="text-lg leading-6 font-medium text-gray-900">Indicaciones</h1>
                    <div className="flex-col flex-1 mt-6 space-y-6">
                        <TextArea value={nonMedicationTreatment} handleChange={handleNonMedicationTreatment} name='nonMedicationTreatment' />
                        <>
                            <div className="flex gap-x-4">
                                <SimpleCombobox error={(props.errors && props.errors[0]?.medicine) ? props.errors[0]?.medicine : null} create={createMedication} required={true} value={medications[0]?.medicine} containerClassName='w-full' Idx={0} handleChange={handleMedication} name="medicine" label={`Medicamento 1`} options={props.medicines} />
                                <TextInput onKeyDown={(event) => { if (/[0-9]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab') { } else { event.preventDefault(); } }} errorMessage={(props.errors && props.errors[0]?.quantity) ? props.errors[0]?.quantity : null} parse={true} required={true} value={medications[0]?.quantity} containerClassName='max-w-[80px]' className='mt-1 shadow-sm pl-2 w-full border border-gray-300 block sm:text-sm rounded-md' name='quantity' Idx={0} handleChange={handleMedication} label="Cantidad" labelClassName='whitespace-nowrap' />
                                <SelectInput required={true} value={medications[0]?.presentation} Idx={0} name='presentation' handleChange={handleMedication} label='Presentación' containerStyle='w-fit' className='shadow-sm w-fit mt-2 mx-auto pr-6 border border-gray-300 block sm:text-sm rounded-md' options={presentation.map((element) => (<option value={element.id} label={element.name}>{element.name}</option>))} />
                                <div className="flex w-1/2">
                                    <TextInput onKeyDown={(event) => { if (/[0-9]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab') { } else { event.preventDefault(); } }} errorMessage={(props.errors && props.errors[0]?.frequencyNumber) ? props.errors[0]?.frequencyNumber : null} parse={true} value={medications[0]?.frequencyNumber} label='Label' labelClassName='invisible' className='shadow-sm pl-2 w-12 mt-1 border rounded-r-none border-gray-300 block sm:text-sm rounded-md' Idx={0} name='frequencyNumber' handleChange={handleMedication} />
                                    <SelectInput required={true} value={medications[0]?.frequency} Idx={0} name='frequency' handleChange={handleMedication} label='Frecuencia' containerStyle='w-full' className='shadow-sm w-full mt-2 mx-auto pr-10 border rounded-l-none border-gray-300 block sm:text-sm rounded-md' options={frequency.map((element) => (<option value={element.id} label={element.name}>{element.name}</option>))} />
                                </div>
                                <div className="flex w-1/2">
                                    <TextInput onKeyDown={(event) => { if (/[0-9]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab') { } else { event.preventDefault(); } }} errorMessage={(props.errors && props.errors[0]?.periodNumber) ? props.errors[0]?.periodNumber : null} disabled={medications[0]?.period === 5} parse={true} value={medications[0]?.period === 5 ? '' : medications[0]?.periodNumber} label='Label' labelClassName='invisible' className={classNames(medications[0]?.period === 5 ? 'bg-gray-200' : 'bg-none', 'shadow-sm pl-2 w-12 mt-1 border rounded-r-none border-gray-300 block sm:text-sm rounded-md')} Idx={0} name='periodNumber' handleChange={handleMedication} />
                                    <SelectInput required={true} value={medications[0]?.period} Idx={0} name='period' handleChange={handleMedication} label='Periodo' containerStyle='w-full' className='shadow-sm w-full mt-2 mx-auto pr-10 border rounded-l-none border-gray-300 block sm:text-sm rounded-md' options={period.map((element) => (<option value={element.id} label={element.name}>{element.name}</option>))} />
                                </div>
                                <Checkbox value={medications[0]?.delivered} handleChange={handleMedication} Idx={0} name='delivered' label={<><p>Entregado</p><p>al paciente</p></>} />
                            </div>
                            <TextArea value={medications[0]?.observations} handleChange={handleMedication} Idx={0} name='observations' label={`Observaciones del medicamento ${1}`} />
                        </>
                        {medications.length > 0 ?
                            <>
                                {medications.map((element, Idx) => {
                                    if (Idx !== 0)
                                        return (
                                            <>
                                                <div className="flex mt-2 gap-x-4">
                                                    <SimpleCombobox create={createMedication} required={true} value={element.medicine} Idx={Idx} handleChange={handleMedication} name="medicine" label={`Medicamento ${Idx + 1}`} containerClassName='w-full' options={props.medicines} />
                                                    <TextInput onKeyDown={(event) => { if (/[0-9]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab') { } else { event.preventDefault(); } }} parse={true} required={true} containerClassName='max-w-[80px]' className='mt-1 shadow-sm pl-2 w-full border border-gray-300 block sm:text-sm rounded-md' value={element.quantity} name='quantity' Idx={Idx} handleChange={handleMedication} label="Cantidad" labelClassName='whitespace-nowrap' />
                                                    <SelectInput required={true} value={element.presentation} Idx={Idx} name='presentation' handleChange={handleMedication} label='Presentación' containerStyle='w-fit' className='shadow-sm w-fit mt-2 mx-auto pr-6 border border-gray-300 block sm:text-sm rounded-md' options={presentation.map((element) => (<option value={element.id} label={element.name}>{element.name}</option>))} />
                                                    <div className="flex items-end w-1/2">
                                                        <TextInput onKeyDown={(event) => { if (/[0-9]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab') { } else { event.preventDefault(); } }} parse={true} className='shadow-sm pl-2 w-12 border rounded-r-none border-gray-300 block sm:text-sm rounded-md' value={element.frequencyNumber} Idx={Idx} name='frequencyNumber' handleChange={handleMedication} />
                                                        <SelectInput required={true} value={element.frequency} Idx={Idx} name='frequency' handleChange={handleMedication} label='Frecuencia' containerStyle='w-full' className='shadow-sm w-full mt-1 mx-auto pr-10 border rounded-l-none border-gray-300 block sm:text-sm rounded-md' options={frequency.map((element) => (<option value={element.id} label={element.name}>{element.name}</option>))} />
                                                    </div>
                                                    <div className="flex items-end w-1/2">
                                                        <TextInput onKeyDown={(event) => { if (/[0-9]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab') { } else { event.preventDefault(); } }} disabled={element?.period === 5 ? true : false} parse={true} className={classNames(element?.period === 5 ? 'bg-gray-200' : 'bg-none', 'shadow-sm pl-2 w-12 border rounded-r-none border-gray-300 block sm:text-sm rounded-md')} value={element?.period === 5 ? '' : element.periodNumber} Idx={Idx} name='periodNumber' handleChange={handleMedication} />
                                                        <SelectInput required={true} value={element.period} Idx={Idx} name='period' handleChange={handleMedication} label='Periodo' containerStyle='w-full' className='shadow-sm w-full mt-1 mx-auto pr-10 border rounded-l-none border-gray-300 block sm:text-sm rounded-md' options={period.map((element) => (<option value={element.id} label={element.name}>{element.name}</option>))} />
                                                    </div>
                                                    <Checkbox value={element.delivered} handleChange={handleMedication} Idx={Idx} name='delivered' label={<><p>Entregado</p><p>al paciente</p></>} />
                                                </div>
                                                <div className="flex mt-2 gap-x-4">
                                                    <TextArea containerClassName='w-full' value={medications[Idx].observations} handleChange={handleMedication} Idx={Idx} name='observations' label={`Observaciones del medicamento ${Idx + 1}`} />
                                                    <button
                                                        type="button"
                                                        className="text-red-600 hover:text-red-900 mt-8 ml-3 pb-1.5"
                                                        onClick={() => removeMedication(Idx)}
                                                    >
                                                        <TrashIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </>
                                        )
                                })}
                            </>
                            :
                            null
                        }
                    </div>
                    <div className="w-full flex justify-center">
                        <button onClick={() => addMedication()} type='button' className="mt-8 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-[#75C9EE] bg-[#F8F8F8] hover:bg-[#F8F8F8]">
                            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                            Agregar otro medicamento
                        </button>
                    </div>
                </div>
            </div>
            <div className="mt-2 px-4 py-4 sm:px-6 bg-gray-100">
                <div className="flex justify-between">
                    <button
                        onClick={() => props.setTab('Refracción')}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm bg-white text-primary hover:text-primary"
                    >
                        Anterior
                    </button>
                    <div className="flex">
                        {props.medicationsRecords && <button type='button' onClick={() => setMedicationsRecordsOpen(true)} className="text-black font-bold leading-[13px] underline mr-10 text-[10px] h-fit my-auto">
                            Ver consultas anteriores
                        </button>}
                        {downloadError &&
                            <p className="font-medium text-[#F51515] text-xs w-1/2 mt-1">
                                {downloadError}
                            </p>
                        }
                        {loadingDownload ? <div className="w-5 h-5 mx-5 border-b-2 border-primary rounded-full animate-spin"></div> : <button
                            onClick={() => downloadPDF()}
                            type="button"
                            className="flex gap-1 mt-2 font-bold text-xs"
                        >
                            <PdfIcon />
                            <p className="mt-1">Imprimir receta</p>
                        </button>}
                        <button
                            onClick={() => props.setTab('Estudios oftalmológicos')}
                            type="button"
                            className="ml-10 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        >
                            Siguiente
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const OphthalmologicalStudies = function (props) {

    const [ophthalmologicalStudy, setOphthalmologicalStudy] = useState({})
    const [firstRender, setFirstRender] = useState(false)
    const [loadingDownload, setLoadingDownload] = useState(false)
    const [downloadError, setDownloadError] = useState(undefined)
    const [selectAll, setSelectAll] = useState(false)

    const handleOphthalmologicalStudy = (field, value) => setOphthalmologicalStudy((prev) => ({ ...prev, [field]: value }))
    const handleExtraStudies = (field, value) => {
        let obj = ophthalmologicalStudy.otherStudies ? ophthalmologicalStudy.otherStudies.slice() : [];
        obj.push(value);
        setOphthalmologicalStudy((prev) => ({ ...prev, ['otherStudies']: obj }));
    }

    const removeStudy = (indx) => {
        let auxStudies = ophthalmologicalStudy.otherStudies.slice(0)
        auxStudies.splice(indx, 1)
        setOphthalmologicalStudy((prev) => ({ ...prev, ['otherStudies']: auxStudies }));
    }

    useEffect(() => {
        if (selectAll) {
            handleOphthalmologicalStudy('ultrasound', true)
            handleOphthalmologicalStudy('tonalCurve', true)
            handleOphthalmologicalStudy('echobiometry', true)
            handleOphthalmologicalStudy('gonioscopy', true)
            handleOphthalmologicalStudy('specularMicroscopy', true)
            handleOphthalmologicalStudy('pachymetry', true)
            handleOphthalmologicalStudy('retinalStudy', true)
            handleOphthalmologicalStudy('topography', true)
            handleOphthalmologicalStudy('orthopticExam', true)
            handleOphthalmologicalStudy('visualField', true)
            handleOphthalmologicalStudy('lambdaTest', true)
            handleOphthalmologicalStudy('orthopticExercises', true)
            handleOphthalmologicalStudy('octRetina', true)
            handleOphthalmologicalStudy('octCornea', true)
            handleOphthalmologicalStudy('octGlaucoma', true)
            handleOphthalmologicalStudy('ubm', true)
        } else {
            handleOphthalmologicalStudy('ultrasound', false)
            handleOphthalmologicalStudy('tonalCurve', false)
            handleOphthalmologicalStudy('echobiometry', false)
            handleOphthalmologicalStudy('gonioscopy', false)
            handleOphthalmologicalStudy('specularMicroscopy', false)
            handleOphthalmologicalStudy('pachymetry', false)
            handleOphthalmologicalStudy('retinalStudy', false)
            handleOphthalmologicalStudy('topography', false)
            handleOphthalmologicalStudy('orthopticExam', false)
            handleOphthalmologicalStudy('visualField', false)
            handleOphthalmologicalStudy('lambdaTest', false)
            handleOphthalmologicalStudy('orthopticExercises', false)
            handleOphthalmologicalStudy('octRetina', false)
            handleOphthalmologicalStudy('octCornea', false)
            handleOphthalmologicalStudy('octGlaucoma', false)
            handleOphthalmologicalStudy('ubm', false)
        }
    }, [selectAll])

    useEffect(() => {
        if (props.consultation.ophthalmologicalStudy && !firstRender) {
            setOphthalmologicalStudy(props.consultation.ophthalmologicalStudy)
            setFirstRender(true)
        } else if (props.consultation && !firstRender) {
            setFirstRender(true)
        }
    }, [props.consultation, firstRender])

    useEffect(() => {
        props.handleChange('ophthalmologicalStudy', ophthalmologicalStudy)
    }, [ophthalmologicalStudy])

    const downloadPDF = function () {
        setLoadingDownload(true);
        setDownloadError('');
        api.ophthalmologicalStudy.get({
            patient: props.consultation.patient, doctor: props.consultation.doctor, generalObservations: props.consultation.ophthalmologicalStudy.generalObservations,
            ophthalmologicalStudy: props.consultation.ophthalmologicalStudy
        }).then(res => {
            setLoadingDownload(false)
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'EstudiosOftalmologicos.pdf');
            document.body.appendChild(link);
            link.click();
        }, async (err) => {
            setDownloadError(JSON.parse(await err.response.data.text()).detail)
            setLoadingDownload(false)
        })
    }

    return (
        <div>
            <div className="px-4 py-6 sm:p-4">
                <div className="grid grid-cols-2">
                    <div className="flex flex-col gap-6">
                        <div>
                            <h1 className="text-lg leading-6 font-medium text-gray-900">Orden de estudio</h1>
                            <span onClick={() => setSelectAll(!selectAll)} className="mt-2 text-primary underline cursor-pointer">{selectAll ? 'Deseleccionar todos' : 'Seleccionar todos'}</span>
                            <div className="mt-2 grid grid-cols-2 gap-y-4">
                                <InlineCheckbox value={ophthalmologicalStudy?.ultrasound} handleChange={handleOphthalmologicalStudy} name='ultrasound' label='Ecografía' />
                                <InlineCheckbox value={ophthalmologicalStudy?.tonalCurve} handleChange={handleOphthalmologicalStudy} name='tonalCurve' label='Curva tensional' />
                                <InlineCheckbox value={ophthalmologicalStudy?.echobiometry} handleChange={handleOphthalmologicalStudy} name='echobiometry' label='Ecobiometría' />
                                <InlineCheckbox value={ophthalmologicalStudy?.gonioscopy} handleChange={handleOphthalmologicalStudy} name='gonioscopy' label='Gonioscopia' />
                                <InlineCheckbox value={ophthalmologicalStudy?.specularMicroscopy} handleChange={handleOphthalmologicalStudy} name='specularMicroscopy' label='Microscopia especular' />
                                <InlineCheckbox value={ophthalmologicalStudy?.pachymetry} handleChange={handleOphthalmologicalStudy} name='pachymetry' label='Paquimetría' />
                                <InlineCheckbox value={ophthalmologicalStudy?.retinalStudy} handleChange={handleOphthalmologicalStudy} name='retinalStudy' label='Estudio de retina' />
                                <InlineCheckbox value={ophthalmologicalStudy?.topography} handleChange={handleOphthalmologicalStudy} name='topography' label='Topografía' />
                                <InlineCheckbox value={ophthalmologicalStudy?.orthopticExam} handleChange={handleOphthalmologicalStudy} name='orthopticExam' label='Examen ortópico' />
                                <InlineCheckbox value={ophthalmologicalStudy?.visualField} handleChange={handleOphthalmologicalStudy} name='visualField' label='Campo visual' />
                                <InlineCheckbox value={ophthalmologicalStudy?.lambdaTest} handleChange={handleOphthalmologicalStudy} name='lambdaTest' label='Test de lambda' />
                                <InlineCheckbox value={ophthalmologicalStudy?.orthopticExercises} handleChange={handleOphthalmologicalStudy} name='orthopticExercises' label='Ejercicios ortópicos' />
                                <InlineCheckbox value={ophthalmologicalStudy?.octRetina} handleChange={handleOphthalmologicalStudy} name='octRetina' label='OCT Retina' />
                                <InlineCheckbox value={ophthalmologicalStudy?.octCornea} handleChange={handleOphthalmologicalStudy} name='octCornea' label='OCT Cornea' />
                                <InlineCheckbox value={ophthalmologicalStudy?.octGlaucoma} handleChange={handleOphthalmologicalStudy} name='octGlaucoma' label='OCT Glaucoma' />
                                <InlineCheckbox value={ophthalmologicalStudy?.ubm} handleChange={handleOphthalmologicalStudy} name='ubm' label='UBM' />
                            </div>
                        </div>
                        <TextArea error={props.errors?.generalObservations} value={ophthalmologicalStudy?.generalObservations} handleChange={handleOphthalmologicalStudy} name='generalObservations' label='Observaciones generales' />
                    </div>
                    <div className='flex gap-3 p-4'>
                        <AddInput value={ophthalmologicalStudy?.otherStudies} unit='Estudio' handleDelete={removeStudy} handleChange={handleExtraStudies} name='otherStudies' label='Agregar otro estudio' className="shadow-sm w-full pl-2 border-0 block sm:text-sm rounded-md focus:ring-0 focus-visible:outline-none" />
                    </div>
                </div>
            </div>
            <div className="px-4 py-4 sm:px-6 bg-gray-100">
                <div className="flex justify-between">
                    <button
                        onClick={() => props.setTab('Medicación')}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm bg-white text-primary hover:text-primary"
                    >
                        Anterior
                    </button>
                    <div className="flex">
                        {downloadError &&
                            <p className="font-medium text-[#F51515] text-xs w-1/2 mt-1">
                                {downloadError}
                            </p>
                        }
                        {loadingDownload ? <div className="w-5 h-5 mx-5 border-b-2 border-primary rounded-full animate-spin"></div> : <button
                            onClick={() => downloadPDF()}
                            type="button"
                            className="flex gap-1 mt-2 font-bold text-xs"
                        >
                            <PdfIcon />
                            <p className="mt-1">Imprimir receta</p>
                        </button>}
                        <button
                            onClick={() => props.setTab('Estudios clínicos')}
                            type="button"
                            className="ml-10 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        >
                            Siguiente
                        </button>
                    </div>
                </div>
            </div>
        </div >
    )
}

const ClinicalStudies = function (props) {

    const [clinicalStudy, setClinicalStudy] = useState({})
    const [firstRender, setFirstRender] = useState(false)
    const [loadingDownload, setLoadingDownload] = useState(false)
    const [downloadError, setDownloadError] = useState(undefined)
    const [selectAll, setSelectAll] = useState(false)

    const handleClinicalStudy = (field, value) => setClinicalStudy((prev) => ({ ...prev, [field]: value }))

    useEffect(() => {
        if (props.age) {
            handleClinicalStudy('age', props.age)
        }
    }, [props.age])

    useEffect(() => {
        if (selectAll) {
            handleClinicalStudy('hemogram', true)
            handleClinicalStudy('vdrl', true)
            handleClinicalStudy('glycemia', true)
            handleClinicalStudy('bloodCrasis', true)
            handleClinicalStudy('urea', true)
            handleClinicalStudy('urineAnalysis', true)
            handleClinicalStudy('creatinine', true)
            handleClinicalStudy('stool', true)
            handleClinicalStudy('lipidProfile', true)
            handleClinicalStudy('worms', true)
            handleClinicalStudy('hiv', true)
            handleClinicalStudy('protozoa', true)
        } else {
            handleClinicalStudy('hemogram', false)
            handleClinicalStudy('vdrl', false)
            handleClinicalStudy('glycemia', false)
            handleClinicalStudy('bloodCrasis', false)
            handleClinicalStudy('urea', false)
            handleClinicalStudy('urineAnalysis', false)
            handleClinicalStudy('creatinine', false)
            handleClinicalStudy('stool', false)
            handleClinicalStudy('lipidProfile', false)
            handleClinicalStudy('worms', false)
            handleClinicalStudy('hiv', false)
            handleClinicalStudy('protozoa', false)
        }
    }, [selectAll])

    const handleExtraAnalysis = (field, value) => {
        let obj = clinicalStudy.otherAnalysis ? clinicalStudy.otherAnalysis.slice() : [];
        obj.push(value);
        setClinicalStudy((prev) => ({ ...prev, ['otherAnalysis']: obj }));
    }

    const removeAnalysis = (indx) => {
        let auxStudies = clinicalStudy.otherAnalysis.slice(0)
        auxStudies.splice(indx, 1)
        setClinicalStudy((prev) => ({ ...prev, ['otherAnalysis']: auxStudies }));
    }

    useEffect(() => {
        if (props.consultation.clinicalStudy && !firstRender) {
            setClinicalStudy(props.consultation.clinicalStudy)
            setFirstRender(true)
        } else if (props.consultation && !firstRender) {
            setFirstRender(true)
        }
    }, [props.consultation, firstRender])

    useEffect(() => {
        props.handleChange('clinicalStudy', clinicalStudy)
    }, [clinicalStudy])

    const downloadPDF = function () {
        setLoadingDownload(true);
        setDownloadError('');
        api.clinicalStudy.get({
            patient: props.consultation.patient, doctor: props.consultation.doctor, generalObservations: props.consultation.clinicalStudy.generalObservations,
            clinicalStudy: props.consultation.clinicalStudy
        }).then(res => {
            setLoadingDownload(false)
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'EstudiosClinicos.pdf');
            document.body.appendChild(link);
            link.click();
        }, async (err) => {
            setDownloadError(JSON.parse(await err.response.data.text()).detail)
            setLoadingDownload(false)
        })
    }

    return (
        <div>
            <div className="px-4 py-6 sm:p-4">
                <div className="grid grid-cols-2">
                    <div className="flex flex-col gap-6">
                        <div>
                            <h1 className="text-lg leading-6 font-medium text-gray-900">Análisis clínicos</h1>
                            <span onClick={() => setSelectAll(!selectAll)} className="mt-2 text-primary underline cursor-pointer">{selectAll ? 'Deseleccionar todos' : 'Seleccionar todos'}</span>
                            <div className="mt-4 grid grid-cols-2 gap-y-4">
                                <InlineCheckbox value={clinicalStudy?.hemogram} handleChange={handleClinicalStudy} name='hemogram' label='Hemograma' />
                                <InlineCheckbox value={clinicalStudy?.vdrl} handleChange={handleClinicalStudy} name='vdrl' label='VDRL' />
                                <InlineCheckbox value={clinicalStudy?.glycemia} handleChange={handleClinicalStudy} name='glycemia' label='Glicemia' />
                                <InlineCheckbox value={clinicalStudy?.bloodCrasis} handleChange={handleClinicalStudy} name='bloodCrasis' label='Crasis sanguínea' />
                                <InlineCheckbox value={clinicalStudy?.urea} handleChange={handleClinicalStudy} name='urea' label='Urea' />
                                <InlineCheckbox value={clinicalStudy?.urineAnalysis} handleChange={handleClinicalStudy} name='urineAnalysis' label='Orina simple' />
                                <InlineCheckbox value={clinicalStudy?.creatinine} handleChange={handleClinicalStudy} name='creatinine' label='Creatinina' />
                                <InlineCheckbox value={clinicalStudy?.stool} handleChange={handleClinicalStudy} name='stool' label='Heces' />
                                <InlineCheckbox value={clinicalStudy?.lipidProfile} handleChange={handleClinicalStudy} name='lipidProfile' label='Perfil lípido' />
                                <InlineCheckbox value={clinicalStudy?.worms} handleChange={handleClinicalStudy} name='worms' label='Vermes' />
                                <InlineCheckbox value={clinicalStudy?.hiv} handleChange={handleClinicalStudy} name='hiv' label='VIH' />
                                <InlineCheckbox value={clinicalStudy?.protozoa} handleChange={handleClinicalStudy} name='protozoa' label='Protozoarios' />
                                <AddInput unit='Análisis' value={clinicalStudy?.otherAnalysis} handleDelete={removeAnalysis} handleChange={handleExtraAnalysis} name='otherAnalysis' label='Otros' className="shadow-sm w-full pl-2 border-0 block sm:text-sm rounded-md focus:ring-0 focus-visible:outline-none" />
                            </div>
                        </div>
                        <TextArea value={clinicalStudy?.generalObservations} handleChange={handleClinicalStudy} name='generalObservations' label='Observaciones generales' />
                    </div>
                    <div className='flex flex-col gap-3 p-4'>
                        <p>Electrocardiograma</p>
                        <div className="flex flex-row gap-3 text-sm font-medium text-gray-700">
                            <p>Edad</p>
                            {`${props.age} años`}
                        </div>
                        <div className="flex gap-3">
                            <p>DX.</p>
                            <p>Prequirurgico</p>
                        </div>
                        <InlineCheckbox value={clinicalStudy?.noEcgTest} handleChange={handleClinicalStudy} name='noEcgTest' label='No fue necesaria una prueba de electrocardiograma' />
                    </div>
                </div>
            </div>
            <div className="px-4 py-4 sm:px-6 bg-gray-100">
                <div className="flex justify-between">
                    <button
                        onClick={() => props.setTab('Estudios oftalmológicos')}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm bg-white text-primary hover:text-primary"
                    >
                        Anterior
                    </button>
                    <div className="flex">
                        {downloadError &&
                            <p className="font-medium text-[#F51515] text-xs w-1/2 mt-1">
                                {downloadError}
                            </p>
                        }
                        {loadingDownload ? <div className="w-5 h-5 mx-5 border-b-2 border-primary rounded-full animate-spin"></div> : <button
                            onClick={() => downloadPDF()}
                            type="button"
                            className="flex gap-1 mt-2 font-bold text-xs"
                        >
                            <PdfIcon />
                            <p className="mt-1">Imprimir receta</p>
                        </button>}
                        <button
                            onClick={() => props.setTab('Cirugía')}
                            type="button"
                            className="ml-10 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        >
                            Siguiente
                        </button>
                    </div>
                </div>
            </div>
        </div >
    )
}

const Surgery = function (props) {

    const [surgeryOrder, setSurgeryOrder] = useState()
    const [firstRender, setFirstRender] = useState(false)
    const [loadingDownload, setLoadingDownload] = useState(false)
    const [loadingDownloadB, setLoadingDownloadB] = useState(false)
    const [downloadError, setDownloadError] = useState(undefined)

    const handleSurgery = (field, value) => {
        setSurgeryOrder((prev) => ({ ...prev, [field]: field === 'bladesType' ? [value] : value }))
    }

    const anesthesia = [
        { id: 1, name: 'Local' },
        { id: 2, name: 'General' },
        { id: 3, name: 'Sedación' },
        { id: 4, name: 'Tópica' },
    ]

    const blades = [
        { id: 1, name: '2.2 mm' },
        { id: 2, name: '2.75 mm' },
        { id: 3, name: '15 grados' },
        { id: 4, name: 'Crescent' },
    ]

    const perfluoro = [
        { id: 1, name: '1000%' },
        { id: 2, name: '1500%' },
        { id: 3, name: '2000%' },
    ]

    useEffect(() => {
        if (props.consultation.surgeryOrder && !firstRender) {
            setSurgeryOrder(props.consultation.surgeryOrder)
            setFirstRender(true)
        } else if (props.consultation && !firstRender) {
            setFirstRender(true)
        }
    }, [props.consultation, firstRender])

    useEffect(() => {
        if (props.userId)
            handleSurgery('primarySurgeon', props.userId)
    }, [props.userId])

    useEffect(() => {
        props.handleChange('surgeryOrder', surgeryOrder)
    }, [surgeryOrder])

    const downloadPDF = function () {
        setLoadingDownload(true);
        setDownloadError('');
        let aux = {}
        if (surgeryOrder.bladesType.length === 1) {
            aux = { ...surgeryOrder, bladesType: surgeryOrder.bladesType[0] }
        } else {
            aux = { ...surgeryOrder }
        }
        api.surgeryOrder.get({
            patient: props.consultation.patient, doctor: props.consultation.doctor, surgeryOrder: aux
        }).then(res => {
            setLoadingDownload(false)
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'OrdenCirugia.pdf');
            document.body.appendChild(link);
            link.click();
        }, async (err) => {
            setDownloadError(JSON.parse(await err.response.data.text()).detail)
            setLoadingDownload(false)
        })
    }

    const downloadEvaluation = function () {
        setLoadingDownload(true);
        setDownloadError('');
        api.surgicalEvaluation.get({
            patient: props.consultation.patient, doctor: props.consultation.doctor, surgeryId: props.consultation.surgeryOrder?.surgeries[0]?.id, anesthesia: props.consultation.surgeryOrder?.anesthesiaType
        }).then(res => {
            setLoadingDownload(false)
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'OrdenEvaluacionQuirurgica.pdf');
            document.body.appendChild(link);
            link.click();
        }, async (err) => {
            setDownloadError(JSON.parse(await err.response.data.text()).detail)
            setLoadingDownload(false)
        })
    }

    return (
        <div>
            <div className="px-4 py-6 sm:p-4">
                <div>
                    <div className="flex flex-col gap-6">
                        <div>
                            <h1 className="text-lg leading-6 font-medium text-gray-900">Orden de cirugía</h1>
                            <div className="grid grid-cols-2">
                                <div>
                                    <div className="flex gap-6 mt-6">
                                        <p className="font-medium text-lg">Seleccionar ojo a consultar</p>
                                        <InlineCheckbox value={surgeryOrder?.leftEye} handleChange={handleSurgery} name='leftEye' label='Ojo izquierdo' />
                                        <InlineCheckbox value={surgeryOrder?.rightEye} handleChange={handleSurgery} name='rightEye' label='Ojo derecho' />
                                    </div>
                                    <CustomCombobox error={props.errors?.surgeries} multiple={true} value={surgeryOrder?.surgeries} handleChange={handleSurgery} containerClassName='relative mt-5' options={props.surgeries} name='surgeries' label='Buscar tipo de cirugía' />
                                </div>
                                <div className="flex flex-col -mt-6 ml-24">
                                    <p>Doctores</p>
                                    <SelectInput value={surgeryOrder?.primarySurgeon} handleChange={handleSurgery} name='primarySurgeon' containerStyle='h-fit w-1/2 mt-2' options={props.doctors.map((element) => (<option key={element.id} value={element.id} label={element.name}>{element.name}</option>))} />
                                    <SelectInput placeholder={true} value={surgeryOrder?.assistantSurgeon} handleChange={handleSurgery} name='assistantSurgeon' containerStyle='h-fit w-1/2 mt-3' options={props.doctors.map((element) => (<option key={element.id} value={element.id} label={element.name}>{element.name}</option>))} />
                                </div>
                            </div>
                            <div className="mt-10 flex space-x-3 justify-between w-5/6">
                                <div className="grid grid-cols-2 gap-x-6 gap-y-4 h-fit mr-6">
                                    <SelectInput placeholder={true} value={surgeryOrder?.anesthesiaType} handleChange={handleSurgery} name='anesthesiaType' containerStyle='h-fit' label='Tipo de anestesia' options={anesthesia.map((element) => (<option key={element.id} value={element.id} label={element.name}>{element.name}</option>))} />
                                    <TextInput value={surgeryOrder?.sutureType} handleChange={handleSurgery} name='sutureType' containerClassName='h-fit' label='Tipo de sutura' />
                                    <div className="flex flex-col">
                                        <p>Implantes</p>
                                        <div className="grid grid-cols-2 gap-3 mt-10">
                                            <InlineCheckbox value={surgeryOrder?.intraocularLens} handleChange={handleSurgery} name='intraocularLens' label='Lente intraocular' />
                                            <TextInput errorMessage={props.errors?.intraocularLensType} value={surgeryOrder?.intraocularLensType} handleChange={handleSurgery} name='intraocularLensType' containerClassName={classNames(surgeryOrder?.intraocularLens ? '' : 'invisible', 'h-fit transform -translate-y-8')} label='Tipo de lente' />
                                            <InlineCheckbox value={surgeryOrder?.valve} handleChange={handleSurgery} name='valve' label='Válvula' />
                                            <TextInput errorMessage={props.errors?.valveType} value={surgeryOrder?.valveType} handleChange={handleSurgery} name='valveType' containerClassName={classNames(surgeryOrder?.valve ? '' : 'invisible', 'h-fit transform -translate-y-8')} label='Tipo de válvula' />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p>Materiales</p>
                                    <div className="grid grid-cols-2 gap-3 whitespace-nowrap mt-2">
                                        <InlineCheckbox value={surgeryOrder?.viscoelastic} handleChange={handleSurgery} name='viscoelastic' label='Viscolastico' />
                                        <TextInput errorMessage={props.errors?.viscoelasticType} value={surgeryOrder?.viscoelasticType} handleChange={handleSurgery} name='viscoelasticType' containerClassName={classNames(surgeryOrder?.viscoelastic ? '' : 'invisible', 'w-32 transform -translate-y-3')} />
                                        <InlineCheckbox value={surgeryOrder?.perfluoroOctanate} handleChange={handleSurgery} name='perfluoroOctanate' label='Perfluoro de octanato' />
                                        <SelectInput placeholder={true} value={surgeryOrder?.perfluoroOctanateType} handleChange={handleSurgery} name='perfluoroOctanateType' className={classNames(surgeryOrder?.perfluoroOctanate ? '' : 'invisible', 'transform -translate-y-2 shadow-sm w-fit mx-auto pr-6 border border-gray-300 block sm:text-sm rounded-md')} options={perfluoro.map((element) => (<option key={element.id} value={element.id} label={element.name}>{element.name}</option>))} />
                                        <InlineCheckbox value={surgeryOrder?.siliconeOil} handleChange={handleSurgery} name='siliconeOil' label='Aceite de silicona' />
                                        <SelectInput className='invisible shadow-sm w-fit mx-auto pr-6 border border-gray-300 sm:text-sm rounded-md' options={[]} />
                                        <InlineCheckbox value={surgeryOrder?.endolaserGas} handleChange={handleSurgery} name='endolaserGas' label='Gas endolaser' />
                                        <SelectInput className='invisible shadow-sm w-fit mx-auto pr-6 border border-gray-300 sm:text-sm rounded-md' options={[]} />
                                        <InlineCheckbox value={surgeryOrder?.silicone} handleChange={handleSurgery} name='silicone' label='Silicona' />
                                        <SelectInput className='invisible shadow-sm w-fit mx-auto pr-6 border border-gray-300 sm:text-sm rounded-md' options={[]} />
                                        <InlineCheckbox value={surgeryOrder?.trypanBlue} handleChange={handleSurgery} name='trypanBlue' label='Azul Tripan' />
                                        <SelectInput className='invisible shadow-sm w-fit mx-auto pr-6 border border-gray-300 sm:text-sm rounded-md' options={[]} />
                                        <InlineCheckbox value={surgeryOrder?.blades} handleChange={handleSurgery} name='blades' label='Cuchilletes' />
                                        <SelectInput placeholder={true} value={surgeryOrder?.bladesType} handleChange={handleSurgery} name='bladesType' className={classNames(surgeryOrder?.blades ? '' : 'invisible', 'transform -translate-y-2 shadow-sm w-full mx-auto pr-6 border border-gray-300 block sm:text-sm rounded-md')} options={blades.map((element) => (<option key={element.id} value={element.id} label={element.name}>{element.name}</option>))} />
                                        <InlineCheckbox value={surgeryOrder?.carbachol} handleChange={handleSurgery} name='carbachol' label='Carbachol' />
                                        <SelectInput className='invisible shadow-sm w-fit mx-auto pr-6 border border-gray-300 sm:text-sm rounded-md' options={[]} />
                                        <InlineCheckbox value={surgeryOrder?.donorTrephine} handleChange={handleSurgery} name='donorTrephine' label='Trepano donante' />
                                        <TextInput errorMessage={props.errors?.donorTrephineDetails} value={surgeryOrder?.donorTrephineDetails} handleChange={handleSurgery} name='donorTrephineDetails' containerClassName={classNames(surgeryOrder?.donorTrephine ? '' : 'invisible', 'w-32 transform -translate-y-3')} />
                                        <InlineCheckbox value={surgeryOrder?.recipientTrephine} handleChange={handleSurgery} name='recipientTrephine' label='Trepano receptor' />
                                        <TextInput errorMessage={props.errors?.recipientTrephineDetails} value={surgeryOrder?.recipientTrephineDetails} handleChange={handleSurgery} name='recipientTrephineDetails' containerClassName={classNames(surgeryOrder?.recipientTrephine ? '' : 'invisible', 'w-32 transform -translate-y-3')} />
                                    </div>
                                </div>
                            </div>
                            <TextArea error={props.errors?.generalObservations} value={surgeryOrder?.generalObservations} handleChange={handleSurgery} name='generalObservations' label='Observaciones generales' containerClassName='mt-4' />
                        </div>
                    </div>
                    <div className='flex flex-col gap-3 p-4'>
                    </div>
                </div>
            </div>
            <div className="px-4 py-4 sm:px-6 bg-gray-100">
                <div className="flex justify-between">
                    <button
                        onClick={() => props.setTab('Estudios clínicos')}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm bg-white text-primary hover:text-primary"
                    >
                        Anterior
                    </button>
                    <div className="flex w-fit gap-3">
                        {downloadError &&
                            <p className="font-medium text-[#F51515] text-xs w-fit mt-1">
                                {downloadError}
                            </p>
                        }
                        {loadingDownloadB ? <div className="w-5 h-5 mx-5 border-b-2 border-primary rounded-full animate-spin"></div> : <button
                            onClick={() => downloadEvaluation()}
                            type="button"
                            className="flex gap-1 mt-2 w-fit font-bold text-xs"
                        >
                            <PdfIcon />
                            <p className="-mt-1">Imprimir solicitud <br /> de evaluación</p>
                        </button>}
                        {loadingDownload ? <div className="w-5 h-5 mx-5 border-b-2 border-primary rounded-full animate-spin"></div> : <button
                            onClick={() => downloadPDF()}
                            type="button"
                            className="flex gap-1 mt-2 w-fit font-bold text-xs"
                        >
                            <PdfIcon />
                            <p className="-mt-1">Imprimir receta</p>
                        </button>}
                        <button
                            onClick={() => props.setTab('Ecobiometría')}
                            type="button"
                            className="ml-10 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        >
                            Siguiente
                        </button>
                    </div>
                </div>
            </div>
        </div >
    )
}

const Ecobiometry = function (props) {

    const [ecobiometry, setEcobiometry] = useState({})
    const [firstRender, setFirstRender] = useState(false)

    const handleEcobiometry = (field, value) => setEcobiometry((prev) => ({ ...prev, [field]: value }))

    useEffect(() => {
        if (props.consultation.ecobiometry && !firstRender) {
            setEcobiometry(props.consultation.ecobiometry)
            setFirstRender(true)
        } else if (props.consultation && !firstRender) {
            setFirstRender(true)
        }
    }, [props.consultation, firstRender])

    useEffect(() => {
        props.handleChange('ecobiometry', { ...ecobiometry, doctor: props.consultation.doctor, patient: props.consultation.patient })
    }, [ecobiometry])

    return (
        <div>
            <div className="px-4 py-6 sm:p-4">
                <div className="flex flex-col">
                    <div className="flex flex-row gap-6">
                        <p className="my-auto font-semibold text-lg">Instrumentación</p>
                        <TextInput containerClassName='w-1/3' value={ecobiometry?.instrumentation} handleChange={handleEcobiometry} name='instrumentation' />
                    </div>
                    <div className="grid grid-cols-3">
                        <div />
                        <div className="grid grid-cols-2 col-span-2 divide-x-4">
                            <p className="w-full text-center mx-auto font-semibold pr-12">OI</p>
                            <p className="w-full text-center mx-auto font-semibold pl-12">OD</p>
                        </div>
                        <p className="my-auto font-semibold text-lg">Longitud axial</p>
                        <div className="grid grid-cols-2 col-span-2 divide-x-4">
                            <TextInput onKeyDown={(event) => { if (/[\d,.]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'ArrowUp' || event.key === 'ArrowDown') { } else { event.preventDefault(); } }} value={ecobiometry?.axialLengthLeft} handleChange={handleEcobiometry} name='axialLengthLeft' containerClassName='h-fit pr-12' />
                            <TextInput onKeyDown={(event) => { if (/[\d,.]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'ArrowUp' || event.key === 'ArrowDown') { } else { event.preventDefault(); } }} value={ecobiometry?.axialLengthRight} handleChange={handleEcobiometry} name='axialLengthRight' containerClassName='h-fit pl-12' />
                        </div>
                        <p className="my-auto font-semibold text-lg">Queratometría</p>
                        <div className="grid grid-cols-2 col-span-2 divide-x-4">
                            <div className="grid grid-cols-2 py-4 gap-6 pr-6">
                                <div>
                                    <div className="flex gap-6">
                                        <p className="my-auto">K1</p>
                                        <TextInput onKeyDown={(event) => { if (/[\d,.]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'ArrowUp' || event.key === 'ArrowDown') { } else { event.preventDefault(); } }} value={ecobiometry?.k1Left} handleChange={handleEcobiometry} name='k1Left' />
                                    </div>
                                    <div className="flex gap-6">
                                        <p className="my-auto">K2</p>
                                        <TextInput onKeyDown={(event) => { if (/[\d,.]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'ArrowUp' || event.key === 'ArrowDown') { } else { event.preventDefault(); } }} value={ecobiometry?.k2Left} handleChange={handleEcobiometry} name='k2Left' />
                                    </div>
                                </div>
                                <TextArea handleChange={handleEcobiometry} name='keratometryLeftDescription' />
                            </div>
                            <div className="grid grid-cols-2 py-4 gap-6 pl-6">
                                <div>
                                    <div className="flex gap-6">
                                        <p className="my-auto">K1</p>
                                        <TextInput onKeyDown={(event) => { if (/[\d,.]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'ArrowUp' || event.key === 'ArrowDown') { } else { event.preventDefault(); } }} value={ecobiometry?.k1Right} handleChange={handleEcobiometry} name='k1Right' />
                                    </div>
                                    <div className="flex gap-6">
                                        <p className="my-auto">K2</p>
                                        <TextInput onKeyDown={(event) => { if (/[\d,.]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'ArrowUp' || event.key === 'ArrowDown') { } else { event.preventDefault(); } }} value={ecobiometry?.k2Right} handleChange={handleEcobiometry} name='k2Right' />
                                    </div>
                                </div>
                                <TextArea handleChange={handleEcobiometry} name='keratometryRightDescription' />
                            </div>
                        </div>
                        <p className="my-auto font-semibold text-lg">Profundidad de CA</p>
                        <div className="grid grid-cols-2 col-span-2 divide-x-4">
                            <TextInput onKeyDown={(event) => { if (/[\d,.]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'ArrowUp' || event.key === 'ArrowDown') { } else { event.preventDefault(); } }} containerClassName='w-full pr-6 pt-4' value={ecobiometry?.anteriorChamberDepthLeft} handleChange={handleEcobiometry} name='anteriorChamberDepthLeft' />
                            <TextInput onKeyDown={(event) => { if (/[\d,.]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'ArrowUp' || event.key === 'ArrowDown') { } else { event.preventDefault(); } }} containerClassName='w-full pl-6 pt-4' value={ecobiometry?.anteriorChamberDepthRight} handleChange={handleEcobiometry} name='anteriorChamberDepthRight' />
                        </div>
                        <p className="my-auto font-semibold text-lg">Fórmula utilizada</p>
                        <div className="grid grid-cols-2 col-span-2 divide-x-4">
                            <TextInput containerClassName='w-full pr-6 pt-4' value={ecobiometry?.formulaUsedLeft} handleChange={handleEcobiometry} name='formulaUsedLeft' />
                            <TextInput containerClassName='w-full pl-6 pt-4' value={ecobiometry?.formulaUsedRight} handleChange={handleEcobiometry} name='formulaUsedRight' />
                        </div>
                    </div>
                    <p className="font-semibold text-lg mt-6">Resultados para emetropía</p>
                    <div className="grid grid-cols-3 gap-x-6">
                        <div />
                        <p className='mx-auto font-semibold'>OI</p>
                        <p className='mx-auto font-semibold'>OD</p>
                        <div />
                        <div className="grid grid-cols-2 text-center font-semibold gap-6">
                            <p>Constante</p>
                            <p>Dioptría</p>
                        </div>
                        <div className="grid grid-cols-2 text-center font-semibold gap-6">
                            <p>Constante</p>
                            <p>Dioptría</p>
                        </div>
                        <p className="my-auto font-semibold">Lente intraocular de cámara anterior</p>
                        <div className="grid grid-cols-2 gap-3">
                            <TextInput onKeyDown={(event) => { if (/[\d,.]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'ArrowUp' || event.key === 'ArrowDown') { } else { event.preventDefault(); } }} value={ecobiometry?.intraocularLensAnteriorLeftConstant} handleChange={handleEcobiometry} name='intraocularLensAnteriorLeftConstant' />
                            <TextInput onKeyDown={(event) => { if (/[\d,.]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'ArrowUp' || event.key === 'ArrowDown') { } else { event.preventDefault(); } }} value={ecobiometry?.intraocularLensAnteriorLeft} handleChange={handleEcobiometry} name='intraocularLensAnteriorLeft' />
                        </div>
                        <div className="grid grid-cols-2 gap-3">
                            <TextInput onKeyDown={(event) => { if (/[\d,.]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'ArrowUp' || event.key === 'ArrowDown') { } else { event.preventDefault(); } }} value={ecobiometry?.intraocularLensAnteriorRightConstant} handleChange={handleEcobiometry} name='intraocularLensAnteriorRightConstant' />
                            <TextInput onKeyDown={(event) => { if (/[\d,.]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'ArrowUp' || event.key === 'ArrowDown') { } else { event.preventDefault(); } }} value={ecobiometry?.intraocularLensAnteriorRight} handleChange={handleEcobiometry} name='intraocularLensAnteriorRight' />
                        </div>
                        <p className="my-auto font-semibold">Lente intraocular de cámara posterior</p>
                        <div className="grid grid-cols-2 gap-3">
                            <TextInput onKeyDown={(event) => { if (/[\d,.]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'ArrowUp' || event.key === 'ArrowDown') { } else { event.preventDefault(); } }} value={ecobiometry?.intraocularLensPosteriorLeftConstant} handleChange={handleEcobiometry} name='intraocularLensPosteriorLeftConstant' />
                            <TextInput onKeyDown={(event) => { if (/[\d,.]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'ArrowUp' || event.key === 'ArrowDown') { } else { event.preventDefault(); } }} value={ecobiometry?.intraocularLensPosteriorLeft} handleChange={handleEcobiometry} name='intraocularLensPosteriorLeft' />
                        </div>
                        <div className="grid grid-cols-2 gap-3">
                            <TextInput onKeyDown={(event) => { if (/[\d,.]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'ArrowUp' || event.key === 'ArrowDown') { } else { event.preventDefault(); } }} value={ecobiometry?.intraocularLensPosteriorRightConstant} handleChange={handleEcobiometry} name='intraocularLensPosteriorRightConstant' />
                            <TextInput onKeyDown={(event) => { if (/[\d,.]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'ArrowUp' || event.key === 'ArrowDown') { } else { event.preventDefault(); } }} value={ecobiometry?.intraocularLensPosteriorRight} handleChange={handleEcobiometry} name='intraocularLensPosteriorRight' />
                        </div>
                        <div />
                        <div className="grid grid-cols-2 gap-3">
                            <TextInput onKeyDown={(event) => { if (/[\d,.]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'ArrowUp' || event.key === 'ArrowDown') { } else { event.preventDefault(); } }} value={ecobiometry?.emmetropyAdditional1LeftConstant} handleChange={handleEcobiometry} name='emmetropyAdditional1LeftConstant' />
                            <TextInput onKeyDown={(event) => { if (/[\d,.]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'ArrowUp' || event.key === 'ArrowDown') { } else { event.preventDefault(); } }} value={ecobiometry?.emmetropyAdditional1Left} handleChange={handleEcobiometry} name='emmetropyAdditional1Left' />
                        </div>
                        <div className="grid grid-cols-2 gap-3">
                            <TextInput onKeyDown={(event) => { if (/[\d,.]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'ArrowUp' || event.key === 'ArrowDown') { } else { event.preventDefault(); } }} value={ecobiometry?.emmetropyAdditional1RightConstant} handleChange={handleEcobiometry} name='emmetropyAdditional1RightConstant' />
                            <TextInput onKeyDown={(event) => { if (/[\d,.]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'ArrowUp' || event.key === 'ArrowDown') { } else { event.preventDefault(); } }} value={ecobiometry?.emmetropyAdditional1Right} handleChange={handleEcobiometry} name='emmetropyAdditional1Right' />
                        </div>
                        <div />
                        <div className="grid grid-cols-2 gap-3">
                            <TextInput onKeyDown={(event) => { if (/[\d,.]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'ArrowUp' || event.key === 'ArrowDown') { } else { event.preventDefault(); } }} value={ecobiometry?.emmetropyAdditional2LeftConstant} handleChange={handleEcobiometry} name='emmetropyAdditional2LeftConstant' />
                            <TextInput onKeyDown={(event) => { if (/[\d,.]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'ArrowUp' || event.key === 'ArrowDown') { } else { event.preventDefault(); } }} value={ecobiometry?.emmetropyAdditional2Left} handleChange={handleEcobiometry} name='emmetropyAdditional2Left' />
                        </div>
                        <div className="grid grid-cols-2 gap-3">
                            <TextInput onKeyDown={(event) => { if (/[\d,.]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'ArrowUp' || event.key === 'ArrowDown') { } else { event.preventDefault(); } }} value={ecobiometry?.emmetropyAdditional2RightConstant} handleChange={handleEcobiometry} name='emmetropyAdditional2RightConstant' />
                            <TextInput onKeyDown={(event) => { if (/[\d,.]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'ArrowUp' || event.key === 'ArrowDown') { } else { event.preventDefault(); } }} value={ecobiometry?.emmetropyAdditional2Right} handleChange={handleEcobiometry} name='emmetropyAdditional2Right' />
                        </div>
                    </div>
                </div>
            </div>
            <div className="px-4 py-4 sm:px-6 bg-gray-100">
                <div className="flex justify-between">
                    <button
                        onClick={() => props.setTab('Cirugía')}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm bg-white text-primary hover:text-primary"
                    >
                        Anterior
                    </button>
                    <div className="flex">
                        <button
                            onClick={(event) => props.handleSubmit(event)}
                            type="button"
                            className="ml-10 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        >
                            {props.loadingSubmit ?
                                <div className="w-5 h-5 mx-5 border-b-2 border-white rounded-full animate-spin"></div> :
                                'Guardar'
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default function FormNewConsultation(props) {

    return (
        <div>
            {props.tab === 'Visión y Tensión' && <VisionTension
                consultation={props.consultation}
                handleChange={props.handleChange}
                setTab={props.changeTab}
                errors={props.errors?.visionTension}
                errorTensionRight={props.errors?.tensionRight}
                errorTensionLeft={props.errors?.tensionLeft}
                errorSampleTime={props.errors?.sampleTime}
                visionFieldsErrors={props.errors?.visionFields}
                handleError={props.handleError}
                idConsultation={props.idConsultation}
            />
            }
            {props.tab === 'Refracción' && <Refraction
                consultation={props.consultation}
                handleChange={props.handleChange}
                errors={props.errors?.refraction}
                setTab={props.changeTab}
                refractionRecords={props.refractionRecords}
            />}
            {props.tab === 'Medicación' && <Medications
                consultation={props.consultation}
                medicines={props.medicines}
                handleChange={props.handleChange}
                setTab={props.changeTab}
                addMedication={props.addMedication}
                removeMedication={props.removeMedication}
                handleMedication={props.handleMedication}
                errors={props.errors?.medications}
                medicationsRecords={props.medicationsRecords}
            />}
            {props.tab === 'Estudios oftalmológicos' && <OphthalmologicalStudies
                consultation={props.consultation}
                handleChange={props.handleChange}
                setTab={props.changeTab}
                errors={props.errors?.ophthalmologicalStudy}
            />}
            {props.tab === 'Estudios clínicos' && <ClinicalStudies
                consultation={props.consultation}
                handleChange={props.handleChange}
                setTab={props.changeTab}
                errors={props.errors?.clinicalStudy}
                age={props.age}
            />}
            {props.tab === 'Cirugía' && <Surgery
                consultation={props.consultation}
                handleChange={props.handleChange}
                surgeries={props.surgeries}
                doctors={props.doctors}
                userId={props.userId}
                setTab={props.changeTab}
                errors={props.errors?.surgery}
                errorEcg={props.errors?.ecg}
                handleSubmit={props.handleSubmit}
                loadingSubmit={props.loadingSubmit}
            />}
            {props.tab === 'Ecobiometría' && <Ecobiometry
                consultation={props.consultation}
                handleChange={props.handleChange}
                setTab={props.changeTab}
                errors={props.errors?.ecobiometry}
                handleSubmit={props.handleSubmit}
                loadingSubmit={props.loadingSubmit}
            />}
        </div>
    )
}